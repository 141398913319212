.detail-section {
  margin: 20px 0;
}

.recommendation-type {
  margin: 0 10px;
}

@media (max-width: 680px) {
  .summary-section {
    min-width: 480px;
    overflow-x: auto;
    overflow-wrap: break-word;
  }
  .ul-summary-section {
    min-width: 480px;
    overflow-x: auto;
    overflow-wrap: break-word;
  }
  .detail-section {
    width: fit-content;
  }
}
