.integrations {
  padding: 20px 0;
}

.page-description {
  padding-top: 30px;
  font-weight: bold;
}

.logo {
  max-height: 40px;
  max-width: 150px;
}

.connect-btn {
  margin-top: 10px;
}
.connect-btn:hover {
  border-color: #4096ff !important;
  background-color: #4096ff !important;
  color: white !important;
}

.connected-btn {
  margin-top: 10px;
}

.connected-btn:hover {
  border-color: green !important;
  background-color: green !important;
  color: white !important;
}
.integration-modal .ant-modal-title {
  color: #030080 !important;
}
.integration-description {
  margin-top: -10px;
}

/* ant overrides */
.ant-card-cover {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100px;
}

.ant-card {
  margin: 20px 0;
}

/* .ant-card-meta-description {
  min-height: 50px;
} */
