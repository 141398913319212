:root {
  --primary-color: #121142;
  --font-color: #030080;
}

/* ant overrides */
.ant-menu-item-selected {
  background-color: rgb(40, 47, 92) !important;
}
.ant-switch-checked {
  background: var(--font-color) !important;
}

.sider {
  background-color: var(--primary-color);
}
.app-header {
  background-color: white;
  font-weight: 700;
  font-family: Inter, sans-serif;
  font-size: 1.5rem;
  color: var(--font-color);
  border-bottom: 0.5px solid lightgrey;
}

.page {
  padding: 0 48px;
  min-height: 100vh;
  background: white;
}

.demo-logo-vertical {
  font-family: "Roboto", sans-serif;

  color: white;
  text-transform: uppercase;
  padding: 0 16px;
}
.demo-logo-vertical .company-title {
  font-weight: 600;
  font-size: 16px;
  margin-top: 24px;
  margin-left: 4px;
  margin-bottom: 40px;
  width: 130px;
  cursor: pointer;
}
.demo-logo-vertical .app-title {
  opacity: 0.4;
  font-size: 14px;
  padding: 0 16px;
  margin-bottom: 4px;
}

.alert-list-item {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  border: 1px solid rgb(232, 232, 238);
  padding: 24px;
  margin-bottom: 24px;
  overflow-x: auto;
}
.alert-list-item:hover {
  box-shadow: rgba(163, 169, 175, 0.08) 1px 4px 8px 2px;
}

.tab-content {
  padding: 0 20px;
}

.summary-section {
  padding: 0 20px;
  font-size: 16px;
}

.key-insights-table {
  font-size: "small";
}

.ul-summary-section {
  font-size: 16px;
}

.logo {
  width: 100%;
  height: 100%;
  max-height: 40px;
  max-width: 120px;
}
.alert-title-section {
  padding-top: 12px;
}
