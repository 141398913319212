.page-description {
  padding-top: 30px;
  margin-bottom: 20px;
  font-weight: bold;
}

.section-title {
  color: #030080;
}

.system-insights-section {
  padding-bottom: 60px;
}
